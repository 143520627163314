<template>
  <v-main
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
  >
    <carregando v-if="$store.state.layout.loading" />
    <router-view />
  </v-main>
</template>

<script>
export default {
  name: "CoreView",
};
</script>
